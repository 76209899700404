/***************************************************************** 
** This is the primary Css file for the site's styling.
** It works along with the style object in app.js which contains 
** setting options for Material Ui's Css in JS setup.
*****************************************************************/

body {
    font-family: 'Open Sans', sans-serif;
}


/* Navigation bar ---------------------------------------------------------- */

.barbar {
    width: 100%;
}

a,
a:hover,
.logogo,
.logogo:hover,
.header-link:hover,
.tech-icon,
.button-styled-link:hover,
#menu-container {
    cursor: pointer;
    text-decoration: none;

    transition: 0.4s;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -o-transition: 0.4s;
}

#scroll-links {
    width: 80%;
    margin-top: 0.85vw;
}

#menu-container {
    display: none;
}

.logogo {
    margin-left: 8.85vw;
    margin-top: 0.4vw;
    width: 212px;
    height: 115.85px;
}

.logogo:hover {
    background-color: #000;
    opacity: 0.7;
}

.header-link {
    color: #fff;
    text-decoration: none;
    float: right;
    font-weight: 500;
    font-size: 16.5px;
    letter-spacing: .85px;
}

.header-link:hover {
    color: rgb(154, 182, 196);
    opacity: 0.9;
}

a:hover,
.tech-icon:hover,
.button-styled-link:hover,
.icon:hover {
    color: rgba(154, 182, 196, 0.582);
    opacity: 0.6;
}

.button-styled-link {
    border: none;
    color: white;
    width: 100%;
    background-color: #04536d;
}

.darken {
    width: 231px;
    height: 115px;
    margin-left: -40px;
}

#menu-container {
    margin: 5px 5px 0 auto;
    float: right;
    border: none;
    color: white;
    background-color: rgba(255, 255, 255, 0);
}


/* Full size navbar links -------------------------------------------------- */

#about {
    margin-left: 5.2vw;
}

#blog {
    margin-left: 5.2vw;
}

#contact-me {
    margin-left: 5.2vw;
    margin-right: -1.5vw;
}


/* Mobile drawer links ----------------------------------------------------- */

#x-icon {
    width: 16px;
    height: 16px;
    margin-top: 19px;
    filter: brightness(0) invert(1);
}

#menu-list {
    margin: 0;
    padding: 0;
}

.drawer {
    width: 220px;
    padding: 0;
    cursor: pointer;
}

#close {
    font-size: 16px;
    height: 52px;
    background-color: #04536d;
    cursor: pointer;
}

#close-text {
    font-size: 13.3px;
    font-weight: 600;
    color: rgb(255, 255, 255);
    height: 50px;
    margin-top: 50px;
}

#projects-link,
#about-link,
#blog-link,
#contact-me-link,
.mobile-menu-link {
    height: 40px !important;
    margin-top: 11px !important;
    margin-bottom: 1px;
    font-size: 14px;
    color: black;
}


/* Skills section icons ---------------------------------------------------- */

.tech-icon {
    color: white;
    position: absolute;
    backface-visibility: hidden;
}

#html-icon:hover {
    width: 103%;
    height: 103%;
}

#css-icon:hover {
    width: 101.5%;
    height: 101.5%;
}

#js-icon:hover {
    width: 97.5%;
    height: 97.5%;
}

#react-icon:hover {
    width: 97%;
    height: 97%;
}

#java-icon:hover {
    width: 99%;
    height: 99%;
}

#node-icon:hover {
    width: 118%;
    height: 118%;
}

#mysql-icon:hover {
    width: 101%;
    height: 101%;
}

#git-icon:hover {
    width: 82.5%;
    height: 82.5%;
}

.iconFlipContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px;
    height: 192px;
    width: 192px;
}

#icon-container {
    height: 38vw;
    width: 90vw;
    margin-top: 3.7vw;
    margin-bottom: 1vw;
    display: flex;
    flex-flow: wrap;
    justify-content: space-around;
}

.backOfCard {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    backface-visibility: hidden;
    text-align: center;
    cursor: pointer;
    width: 124px;
    height: 182px;
    z-index: 101;
    padding: 10px 5px 5px 5px;
    border-radius: 6px;
    border-top: 1px solid rgba(117, 117, 117, 0.171);
    background-color: #fff;

    transform: rotateY(90deg);
    transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;

    box-shadow: 1px 4px 9px 0 rgba(0, 0, 0, .21), 0 4px 32px rgba(50, 0, 0, 0.12);
    -webkit-box-shadow: 1px 4px 9px 0 rgba(0, 0, 0, .21), 0 4px 32px rgba(50, 0, 0, 0.12);
    -moz-box-shadow: 1px 4px 9px 0 rgba(0, 0, 0, .21), 0 4px 32px rgba(50, 0, 0, 0.12);
    -ms-box-shadow: 1px 4px 9px 0 rgba(0, 0, 0, .21), 0 4px 32px rgba(50, 0, 0, 0.12);
    -o-box-shadow: 1px 4px 9px 0 rgba(0, 0, 0, .21), 0 4px 32px rgba(50, 0, 0, 0.12);
}

.backOfCard a {
    color: #04536d;
}


/* Card layout ------------------------------------------------------------- */

#stack {
    padding-bottom: 40px;
}

p.MuiTypography-root.MuiTypography-body1 {
    font-size: .98em;
}

.footer-card {
    border: 1px solid #2f2f2f33;
    border-radius: 10px;

    box-shadow: 4px 5px 12px 3px rgba(0, 0, 0, 0.08), 0px 1px 18px rgba(130, 0, 78, 0.02);
    -webkit-box-shadow: 4px 5px 12px 3px rgba(0, 0, 0, 0.08), 0px 1px 18px rgba(130, 0, 78, 0.02);
    -moz-box-shadow: 4px 5px 12px 3px rgba(0, 0, 0, 0.08), 0px 1px 18px rgba(130, 0, 78, 0.02);
    -ms-box-shadow: 4px 5px 12px 3px rgba(0, 0, 0, 0.08), 0px 1px 18px rgba(130, 0, 78, 0.02);
    -o-box-shadow: 4px 5px 12px 3px rgba(0, 0, 0, 0.08), 0px 1px 18px rgba(130, 0, 78, 0.02);
}


/* Footer section ---------------------------------------------------------- */

.bottom-container {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    margin: 150px 0 0 0;
    text-align: center;
    position: relative;
    display: inline-block;
}

#footer {
    margin: 0;
    padding: 0;
    border: 0;
    height: auto;
}

#about-text {
    position: absolute;
    top: 7px;
    left: 10%;
    width: 500px;
    z-index: 3;
}

#about-contact-link,
#about-contact-link:visited {
    color: #04536d;
    font-weight: 700;
}

.skyline-img {
    position: relative;
    max-width: 100%;
    height: unset;
    z-index: 0;
    top: 5px;
    margin: 0;
    padding: 0;
    border: 0;
}

#red-pin {
    left: 44%;
    width: 10%;
    top: 47%;
    height: unset;
    position: absolute;
}


/* Social link icons ------------------------------------------------------- */

#social {
    position: absolute;
    margin-top: 1.8vw;
    z-index: 2;
    width: 230px;
    height: 205px;
    padding-top: 10px;
    left: 62%;
    top: -4%;
}

#social-block {
    float: left;
}

#github-img {
    margin-left: -93px;
    margin-top: 5px;
}

#github {
    position: absolute;
    margin-top: -48px;
    margin-left: 95px;
}

#linkedin-img {
    margin-left: -90px;
    margin-top: 10px;
}

#linkedin {
    position: absolute;
    margin-top: -50px;
    margin-left: 95px;
}


/* Card Animations -> image is the front and backOfCard is the back side --- */

.image[flipstate='1'] {
    animation: flip-out-90 .40s linear .05s 1 normal forwards;
}

@keyframes flip-out-90 {
    0% {
        transform: rotateY(0deg);
    }
    12.5% {
        transform: rotateY(15deg);
    }
    25% {
        transform: rotateY(30deg);
    }
    50% {
        transform: rotateY(90deg);
    }
    100% {
        transform: rotateY(90deg);
    }
}

.image[flipstate='2'] {
    animation: flip-in-90 .40s linear 0s 1 normal forwards;
}

@keyframes flip-in-90 {
    0% {
        transform: rotateY(90deg);
    }
    50% {
        transform: rotateY(90deg);
    }
    65.5% {
        transform: rotateY(30deg);
    }
    75% {
        transform: rotateY(15deg);
    }
    100% {
        transform: rotateY(0deg);
    }
}

.backOfCard[flipstate='1'] {
    animation: flip-out-180 .40s linear 0s 1 normal forwards;
}

@keyframes flip-out-180 {
    0% {
        transform: rotateY(270deg);
    }
    50% {
        transform: rotateY(270deg);
    }
    65% {
        transform: rotateY(285deg);
    }
    75% {
        transform: rotateY(300deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}

.backOfCard[flipstate='2'] {
    animation: flip-in-180 .40s linear 0s 1 normal forwards;
}

@keyframes flip-in-180 {
    0% {
        transform: rotateY(360deg);
    }
    12.5% {
        transform: rotateY(345deg);
    }
    25% {
        transform: rotateY(330deg);
    }
    50% {
        transform: rotateY(270deg);
    }
    100% {
        transform: rotateY(270deg);
    }
}
